<template>
    <b-overlay :show="is_busy" rounded="sm">
        <section id="dashboard-analytics">
            <div class="text-center mb-2">
                 <img :src="school.logo" class="img-fluid" style="height:70px" v-if="school.logo">
                <h4>Welcome <span v-if="user.type==2 || user.type==4">{{ user.last_name }} {{ user.first_name }}</span> to your platform  at {{ school.school_name }}</h4>
            </div>
           
            <!-- <div class="p-2 alert alert-info" v-if="user.type==1">
                <p class="text-mute text-center">You are on <b><span class="text-danger">{{ school.plan.name }}</span></b> package plan. You have <b><span class="text-danger">{{ school.remainingDays }}</span></b> days before expiration</p>
            </div> -->
            <!-- <b-row class="match-height"  v-if="user.type!=4">
                <b-col xl="12" md="12">
                    <b-card no-body class="card-statistics">
                        <b-card-header>
                            <b-card-title>Statistics</b-card-title>
                        </b-card-header>
                        <b-card-body class="statistics-body">
                            <b-row>
                                <b-col key="UserIcon" xl="3" sm="6" class="mb-2 mb-xl-0">
                                    <b-media no-body>
                                        <b-media-aside class="mr-2">
                                            <b-avatar size="48" variant="light-info">
                                                <feather-icon size="24" icon="UserIcon"/>
                                            </b-avatar>
                                        </b-media-aside>
                                        <b-media-body class="my-auto">
                                            <h4 class="font-weight-bolder mb-0">
                                                {{ students }}
                                            </h4>
                                            <b-card-text class="font-small-3 mb-0">
                                                Learners
                                            </b-card-text>
                                        </b-media-body>
                                    </b-media>
                                </b-col>

                                <b-col key="users" xl="3" sm="6" class="mb-2 mb-xl-0">
                                    <b-media no-body>
                                        <b-media-aside class="mr-2">
                                            <b-avatar size="48" variant="light-success">
                                                <feather-icon size="24" icon="users"/>
                                            </b-avatar>
                                        </b-media-aside>
                                        <b-media-body class="my-auto">
                                            <h4 class="font-weight-bolder mb-0">
                                                {{ staff }}
                                            </h4>
                                            <b-card-text class="font-small-3 mb-0">
                                                Teachers
                                            </b-card-text>
                                        </b-media-body>
                                    </b-media>
                                </b-col>

                                <b-col key="TrendingUpIcon" xl="3" sm="6" class="mb-2 mb-xl-0">
                                    <b-media no-body>
                                        <b-media-aside class="mr-2">
                                            <b-avatar size="48" variant="light-danger">
                                                <feather-icon size="24" icon="TrendingUpIcon"/>
                                            </b-avatar>
                                        </b-media-aside>
                                        <b-media-body class="my-auto">
                                            <h4 class="font-weight-bolder mb-0">
                                                {{ classes }}
                                            </h4>
                                            <b-card-text class="font-small-3 mb-0">
                                                Classes
                                            </b-card-text>
                                        </b-media-body>
                                    </b-media>
                                </b-col>

                                <b-col key="BoxIcon" xl="3" sm="6" class="mb-2 mb-xl-0">
                                    <b-media no-body>
                                        <b-media-aside class="mr-2">
                                            <b-avatar size="48" variant="light-primary">
                                                <feather-icon size="24" icon="BoxIcon"/>
                                            </b-avatar>
                                        </b-media-aside>
                                        <b-media-body class="my-auto">
                                            <h4 class="font-weight-bolder mb-0">
                                                {{ subjects }}
                                            </h4>
                                            <b-card-text class="font-small-3 mb-0">
                                                Subjects
                                            </b-card-text>
                                        </b-media-body>
                                    </b-media>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
             -->
            
            <div class="row" v-if="user.type==1">
                <div class="col-md-3 col-6">
                    <router-link :to="{ name: 'enroll' }">
                        <div class="card p-2">
                            <div class="card-body text-center">
                                <feather-icon size="24" icon="AwardIcon"/><br>
                                Admission
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-3 col-6">
                    <router-link :to="{ name: 'classes' }">
                        <div class="card p-2">
                            <div class="card-body text-center">
                                <feather-icon size="24" icon="AirplayIcon"/><br>
                                Classes
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-3 col-6">
                    <router-link :to="{ name: 'subjects' }">
                        <div class="card p-2">
                            <div class="card-body text-center">
                                <feather-icon size="24" icon="BookIcon"/><br>
                                Subjects
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-3 col-6">
                    <router-link :to="{ name: 'academic-settings' }">
                        <div class="card p-2">
                            <div class="card-body text-center">
                                <feather-icon size="24" icon="SettingsIcon"/><br>
                                Settings
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-3 col-6">
                    <router-link :to="{ name: 'student' }">
                        <div class="card p-2">
                            <div class="card-body text-center">
                                <feather-icon size="24" icon="UserIcon"/><br>
                                Learners
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-3 col-6">
                    <router-link :to="{ name: 'staff' }">
                        <div class="card p-2">
                            <div class="card-body text-center">
                                <feather-icon size="24" icon="MehIcon"/><br>
                                Staff
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-3 col-6">
                    <router-link :to="{ name: 'add-result' }">
                        <div class="card p-2">
                            <div class="card-body text-center">
                                <feather-icon size="24" icon="EditIcon"/><br>
                                Add Result
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-3 col-6">
                    <router-link :to="{ name: 'view-result' }">
                        <div class="card p-2">
                            <div class="card-body text-center">
                                <feather-icon size="24" icon="GridIcon"/><br>
                                View Result
                            </div>
                        </div>
                    </router-link>
                </div>
                
                <div class="col-md-3 col-6">
                    <router-link :to="{ name: 'barcode' }">
                        <div class="card p-2">
                            <div class="card-body text-center">
                                <feather-icon size="24" icon="CameraIcon"/><br>
                                Scan Learner
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-3 col-6">
                    <router-link :to="{ name: 'attendanceReport' }">
                        <div class="card p-2">
                            <div class="card-body text-center">
                                <feather-icon size="24" icon="ClockIcon"/><br>
                                Attendance
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-3 col-6">
                    <router-link :to="{ name: 'staffBarcode' }">
                        <div class="card p-2">
                            <div class="card-body text-center">
                                <feather-icon size="24" icon="CameraIcon"/><br>
                                Scan Staff
                            </div>
                        </div>
                    </router-link>
                </div> 
                <div class="col-md-3 col-6">
                    <router-link :to="{ name: 'staffAttendanceReport' }">
                        <div class="card p-2">
                            <div class="card-body text-center">
                                <feather-icon size="24" icon="ClockIcon"/><br>
                                Staff Attendance
                            </div>
                        </div>
                    </router-link>
                </div>

                
                
                
                <!-- <div class="col-md-6">
                    <div class="card">
                        <div class="card-body text-center">
                            <img :src="school.logo" class="img-fluid" style="height:70px" v-if="school.logo">
                            <p class="p-1">
                                <span style="font-size: 20px; font-weight: bold">{{ school.school_name }}</span>
                                <br> {{ school.slogan }}<br>
                                {{ school.address }} <br>
                                {{ school.email }} <br>
                                {{ school.phone }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6"> -->
                    <!-- {{ school.subscriptions }} -->
                <!-- </div> -->
            </div>

            <div class="row" v-if="user.type==4">
                <div class="col-md-3">
                    <!-- {{ school.subscriptions }} -->
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body text-center">
                            <img :src="school.logo" class="img-fluid" style="height:70px" v-if="school.logo">
                            <p class="p-1">
                                <span style="font-size: 20px; font-weight: bold">{{ school.school_name }}</span>
                                <br> {{ school.slogan }}<br>
                                {{ school.address }} <br>
                                {{ school.email }} <br>
                                {{ school.phone }}
                            </p>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
    </b-overlay>
</template>

<script>

import { BCard, BAvatar, BRow, BCol } from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import axios from 'axios';


export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
  },

  data() {
    return {
      school: '',
      staff: '',
      students: '',
      subjects: '',
      classes: '',
      is_busy: false,
      user: '',
      events: [],

        items: [],
        fields: [
            { key: 'index', label: '' },
            { key: 'slug', label: 'Name'},
            { key: 'title', label: 'Title'},
            { key: 'teacher', label: 'Form Teacher'},
            { key: 'subject', label: 'Subject'},
            { key: 'number_of_student', label: 'Student' },
            { key: 'promotion', label: 'Promotion Class' },
            { key: 'actions', label: 'Actions' }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 15,
        bordered: true,
        hover: true,
        fixed: true,
        pageOptions: [5, 10, 15, 25, 50, 100, { value: 100, text: "All" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
    }
  },

  mounted() {
    // if (localStorage.getItem('reloaded')) {
    // } 
    // else {
    //     // Set a flag so that we know not to reload the page twice.
    //     localStorage.setItem('reloaded', '1');
    //     location.reload();
    // }
    this.user = JSON.parse(localStorage.getItem('user')).data.user
    this.school = JSON.parse(localStorage.getItem('user')).data.school
  },

  created() {
    // this.globalHelper();
    
    // this.load();
  },
 
  methods: {
    load() {
        if(this.is_busy) return;
        this.is_busy = true;
        axios.get('/dashboard')
        .then(res => {
          this.staff = res.data.data.staff;
          this.students = res.data.data.students;
          this.subjects = res.data.data.subjects;
          this.classes = res.data.data.classes;
        })
        .catch(err => {
            console.log(err);
        })
        .finally(() => {
            this.is_busy = false;
        });
    },
    kFormatter,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>